
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import tableCheck from "@/mixins/table-check";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { Pageniation, Loading } from "@/utils/decorator";
import t from "@common/src/i18n/t";
import { MessageBox, Message } from 'element-ui';
const appVersion = namespace("appVersion");
const base = namespace("base");
const consult = namespace("consult");

@Component({
  components: { TableEnableFlag }
})
@Pageniation("loadListData")
export default class AppVersion extends Mixins(tableCheck) {
  @base.Action getDictionaryList;
  @appVersion.Action getVersionList;
  @appVersion.Action updateVersion;
  @appVersion.Action deleteVersion;
  dayjs = dayjs;
  visible: boolean = true;
  searchForm = {
    applicationCode: ""
  };
  emptyToLine = emptyToLine;
  versionList: any[] = [];
  storeTypeList: any[] = [];
  publisherList: any = [];
  get searchList() {
    return [
      {
        label: "应用Code",
        type: "input",
        value: "",
        prop: "applicationCode"
      }
    ];
  }
  //多行文本省略
  filters(value) {
    if (!value) return "";
    if (value.length > 20) {
      return value.slice(0, 20) + "...";
    }
    return value;
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    console.log(this.searchForm, "searchfrom");

    this.loadListData();
  }
  created() {
    this.init();
  }

  async init() {
    this.loadListData();
  }

  @Loading()
  loadListData() {
    return this.getVersionList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.versionList = data.data.list;
      //   console.log(data, "data");
      return data;
    });
  }
  handleEdit(row) {
    this.$router.push(`/app/app-version-edit/${row.id}`);
  }

  handleDelete(row) {
    MessageBox.confirm("此操作将永久删除该条目, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      this.deleteVersion({
        id: row.id
      }).then(() => {
        this.$message.success("删除成功");
        this.$router.push("/app/app-version-list");
      });
    });
  }
}
